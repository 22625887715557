<template>
    <div class="wrapper padding-lr-sm">
        <div class="flex align-center padding link margin-bottom">
            <div class="margin-right-sm">员工信息填写链接：</div>
            <div>
                <el-input v-model="userPath" disabled class="margin-right-sm"></el-input>
                <button type="button" class="copy-btn"
                        v-clipboard:copy="userPath"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError">复制
                </button>
            </div>
        </div>

        <div class="preview">

            <h3 class="preview-title margin-bottom">兼职员工信息</h3>
            <el-form :model="infoForm"
                     label-width="140px"
                     :rules="rules" ref="infoForm" class="add-form">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="xingming">
                            <el-input v-model="infoForm.xingming" placeholder="请填写姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="昵称/英文" prop="nicheng">
                            <el-input v-model="infoForm.nicheng" placeholder="请填写昵称/英文"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="xingbie">
                            <el-select v-model="infoForm.xingbie" placeholder="请选择性别" class="w-all">
                                <el-option
                                        v-for="item in sexList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机" prop="shoujihao">
                            <el-input v-model="infoForm.shoujihao" placeholder="请填写手机"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生年月日" prop="chushengnianyueri">
                            <el-date-picker align="center"
                                            class="w-all"
                                            value-format="yyyy-MM-dd"
                                            v-model="infoForm.chushengnianyueri"
                                            type="date"
                                            placeholder="请填写出生年月日">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证/通行证" prop="shenfenzhenghao">
                            <el-input v-model="infoForm.shenfenzhenghao" placeholder="请填写身份证/通行证"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="个人邮箱" prop="youxiang">
                            <el-input v-model="infoForm.youxiang" placeholder="请填写邮箱"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="个人微信" prop="weixinhao">
                            <el-input v-model="infoForm.weixinhao" placeholder="请填写个人微信"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="家庭地址" prop="jiatingdizhi">
                            <el-input v-model="infoForm.jiatingdizhi" placeholder="请填写家庭地址"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮编" prop="youbian">
                            <el-input type="number" v-model="infoForm.youbian" placeholder="请填写邮编"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-divider></el-divider>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="毕业学校" prop="biyeyuanxiao">
                            <el-input v-model="infoForm.biyeyuanxiao" placeholder="请填写毕业学校"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="在校专业" prop="zaixiaozhuanye">
                            <el-input v-model="infoForm.zaixiaozhuanye" placeholder="请填写在校专业"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="最高学历" prop="zuigaoxueli">
                            <el-input v-model="infoForm.zuigaoxueli" placeholder="请填写最高学历"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="毕业时间" prop="biyeshijian">
                            <el-date-picker align="center"
                                    class="w-all"
                                    v-model="infoForm.biyeshijian"
                                    type="date"
                                    placeholder="请填写毕业时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="现居住地址" prop="xianjudizhi">
                            <el-input v-model="infoForm.xianjudizhi" placeholder="请填写现居住地址"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="最早入职时间" prop="zuizaoruzhishijian">
                            <el-date-picker align="center"
                                            class="w-all"
                                            v-model="infoForm.zuizaoruzhishijian"
                                            type="date"
                                            placeholder="请填写最早入职时间">
                            </el-date-picker>

                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="紧急联系人姓名" prop="jinjirenxingming">
                            <el-input v-model="infoForm.jinjirenxingming" placeholder="请填写紧急联系人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="紧急联系人电话" prop="jinjirendianhua">
                            <el-input v-model="infoForm.jinjirendianhua" placeholder="请填写紧急联系人电话"></el-input>
                        </el-form-item>
                        <el-form-item label="与本人关系" prop="yubenrenguanxi">
                            <el-input v-model="infoForm.yubenrenguanxi" placeholder="请填写与本人关系"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行卡开户行" prop="kaihuhang">
                            <el-input v-model="infoForm.kaihuhang" placeholder="请填写银行卡开户行"></el-input>
                        </el-form-item>

                        <el-form-item label="开户行支行名称" prop="zhihangmingcheng">
                            <el-input v-model="infoForm.zhihangmingcheng" placeholder="请填写开户行支行名称"></el-input>
                        </el-form-item>

                        <el-form-item label="银行卡号" prop="yinhangkahao">
                            <el-input v-model="infoForm.yinhangkahao" placeholder="请填写银行卡号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label-width="0" class="flex justify-around">
                    <el-button class="submit-btn" type="primary" @click="submitForm('infoForm')">保存</el-button>
                </el-form-item>
            </el-form>


        </div>

    </div>
</template>

<script>

import rules from "@/utils/rules";
import {perfectStaffPartTime, perfectStaffShow} from "@/api/entry";
import {zhDate} from "@/utils";
import {sexList} from "@/utils/const";

export default {
    name: "OrgList",
    data() {
        return {
            sexList: sexList,
            infoForm: {
                xingming: '',
                nicheng: '',
                xingbie: '',
                shoujihao: '',
                chushengnianyueri: '',
                shenfenzhenghao: '',
                youxiang: '',
                jiatingdizhi: '',
                youbian: '',
                weixinhao: '',
                biyeyuanxiao: '',
                zaixiaozhuanye: '',
                zuigaoxueli: '',
                biyeshijian: '',
                xianjudizhi: '',
                zuizaoruzhishijian: '',
                jinjirenxingming: '',
                jinjirendianhua: '',
                yubenrenguanxi: '',
                kaihuhang: '',
                zhihangmingcheng: '',
                yinhangkahao: '',
            },
            rules: {
                xingming: rules.mustInput,
                nicheng: rules.mustInput,
                xingbie: rules.mustSelect,
                shoujihao: rules.phone,
                shenfenzhenghao: rules.mustInput,
                youxiang: rules.email,
                jiatingdizhi: rules.mustInput,
                youbian: rules.mustInput,
                weixinhao: rules.mustInput,

                biyeyuanxiao: rules.mustInput,
                zaixiaozhuanye: rules.mustInput,
                zuigaoxueli: rules.mustInput,
                biyeshijian: rules.mustDate,
                xianjudizhi: rules.mustInput,
                zuizaoruzhishijian: rules.mustDate,
                jinjirenxingming: rules.mustInput,
                jinjirendianhua: rules.phone,
                yubenrenguanxi: rules.mustInput,
                kaihuhang: rules.mustInput,
                zhihangmingcheng: rules.mustInput,
                yinhangkahao: rules.mustInput,


            },
        }
    },
    created() {
        this.$emit("setIndex", '/admin/expertList', "兼职员工信息", true);
        this.id = this.$route.params.id
        this.staffShow()
    },
    computed: {
        userPath() {
          return 'http://hr.shareone.cc/staffInfo/' + this.id + '/1'
        }
    },
    components: {},
    methods: {
        onCopy() {
            this.$message.success('复制成功')
        },
        onError() {
            this.$message.error('复制失败')
        },
        async staffShow() {
            const res = await perfectStaffShow(this.id, 3)
            if(!res.data) return
            this.infoForm = {
                xingming: res.data.xingming,
                nicheng: res.data.nicheng,
                xingbie: res.data.xingbie,
                shoujihao: res.data.shoujihao,
                chushengnianyueri: res.data.chushengnianyueri,
                shenfenzhenghao: res.data.shenfenzhenghao,
                youxiang: res.data.youxiang,
                jiatingdizhi: res.data.jiatingdizhi,
                youbian: res.data.youbian,
                weixinhao: res.data.weixinhao,
                biyeyuanxiao: res.data.biyeyuanxiao,
                zaixiaozhuanye: res.data.zaixiaozhuanye,
                zuigaoxueli: res.data.zuigaoxueli,
                biyeshijian: new Date(res.data.biyeshijian),
                xianjudizhi: res.data.xianjudizhi,
                zuizaoruzhishijian: new Date(res.data.zuizaoruzhishijian),
                jinjirenxingming: res.data.jinjirenxingming,
                jinjirendianhua: res.data.jinjirendianhua,
                yubenrenguanxi: res.data.yubenrenguanxi,
                kaihuhang: res.data.kaihuhang,
                zhihangmingcheng: res.data.zhihangmingcheng,
                yinhangkahao: res.data.yinhangkahao,
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sureAdd()
                }
            });
        },
        async sureAdd() {
            let _state = await perfectStaffPartTime(
                this.id,
                this.infoForm.xingming,
                this.infoForm.nicheng,
                this.infoForm.xingbie,
                this.infoForm.shoujihao,
                this.infoForm.chushengnianyueri,
                this.infoForm.shenfenzhenghao,
                this.infoForm.youxiang,
                this.infoForm.jiatingdizhi,
                this.infoForm.youbian,
                this.infoForm.weixinhao,
                this.infoForm.biyeyuanxiao,
                this.infoForm.zaixiaozhuanye,
                this.infoForm.zuigaoxueli,
                zhDate(new Date(this.infoForm.biyeshijian)),
                this.infoForm.xianjudizhi,
                zhDate(new Date(this.infoForm.zuizaoruzhishijian)),
                this.infoForm.jinjirenxingming,
                this.infoForm.jinjirendianhua,
                this.infoForm.yubenrenguanxi,
                this.infoForm.kaihuhang,
                this.infoForm.zhihangmingcheng,
                this.infoForm.yinhangkahao,
            )

            if (_state.status === 200) {
                this.$message.success(_state.info)
                await this.staffShow()
            } else {
                this.$message.error(_state.info)
            }
        },
    },
    filters: {},
    watch: {},
}
</script>

<style scoped lang="scss">
.wrapper {
  .link {
    padding: 10px;
    border-bottom: 2px solid #368ad1;

    div {
      font-size: 18px;
    }

    .el-input {
      width: 500px;
    }

    .copy-btn {
      width: 100px;
    }

  }

  .preview {
    width: 100%;
    min-height: 500px;
    padding: 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;

    .preview-title {
      text-align: center;
      line-height: 60px;
      font-size: 22px;
    }
  }
}
</style>
